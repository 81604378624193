const links = {
    "links": [
      {
        "link": "/",
        "label": "Home"
      },
      {
        "link": "/contact-us",
        "label": "Contact Us"
      },
      // {
      //   "link": "#about-us",
      //   "label": "About Us",
      //   "links": [
      //     {
      //       "link": "/our-process",
      //       "label": "Our Process"
      //     },
      //     {
      //       "link": "/our-services",
      //       "label": "Our Services"
      //     },
      //     {
      //       "link": "/our-clients",
      //       "label": "Our Clients"
      //     },
      //     {
      //       "link": "/faq",
      //       "label": "FAQs"
      //     }
      //   ]
      // },
      // {
      //   "link": "#tools",
      //   "label": "Tools",
      //   "links": [
      //     {
      //       "link": "/gst-calculator",
      //       "label": "GST Calculator"
      //     },
      //     {
      //       "link": "/demo",
      //       "label": "Book a demo"
      //     },
      //     {
      //       "link": "/forums",
      //       "label": "Forums"
      //     }
      //   ]
      // },
    ]
  }

  export default links;