export const welcomeConsoleLog = () => {
    console.log(
        `
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░▒▓▓▓░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░▓███░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░▒▒▒████▒▒▒▒▒▒▒▒▒▒▒▒
░░░░░▒▓████████▓░░░███████████▒███▓▓▒▒▒▒
░░░▒█████▓▓▓▓████▒░▓▓▓███████▓▒▓████▓▒▒▒
░░▓███▒░░░░░░░░▒░░░░░░▒▒████▒▒▒▒▒▓███▓▒▒
░▒███░░░░░░░░░░░░░░░░░▒▒▓▓▓▓▒▒▒▒▒████▒▒▒
░███▓░░░░░░░░░░░░░░░░░▒▒▓███████████▓▒▒▒
░▓███░░░░░░░░░░░░░░░░░▒▒██████████▓▒▒▒▒▒
░░███▓░░░░░░░░░░░░░░░░▒▒████▒▒▒▒▒▒▒▒▒▒▒▒
░░░████▓▒░░░░▒▓██▒░░░░▒▒████▒▒▒▒▒▒▒▒▒▒▒▒
░░░░▒███████████▓▒░░░░▒▒████▒▒▒▒▒▒▒▒▒▒▒▒
░░░░░░░▒▒▓▓▓▓▒▒░░░░░░░▒▒▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒
░░░░░░░░░░░░░░░░░░░░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░

        `
    )
}